<template>
    <div id="section-transition" ref="sectionTransition" v-if="active">
        <div class="loader__mask" ref="mask">
            <div class="loader__mask__centered" ref="centered">
                <div>
                    <div class="leaves">
                        <svg role = "img" aria-label = "A graphic icon of a leaf." v-for="(leaf, i) in 5" class="leaf" :class="'leaf' + (i+1)" enable-background="new 0 0 56.4 56.4" viewBox="0 0 56.4 56.4" xmlns="http://www.w3.org/2000/svg">
                            <path :id="'leaf' + (i+1)" d="m1.4 55v-27.1c0-7.3 3-13.9 7.8-18.7s11.4-7.8 18.7-7.8h27.1v27.1c0 7.3-3 13.9-7.8 18.7s-11.4 7.8-18.7 7.8z" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="2.8346" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="transition__white transition--full" ref="white"></div>
        <div class="transition__black transition--full" ref="black"></div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import router from '../router'

    import gsap, {Expo, Linear, Power3} from 'gsap'

    export default {
        data() {
            return {
                active: true,
                next: null,
                wipeSpeed: 0.7,
                easing: Expo.easeInOut,
                firstLoad: true,
                debug: false
            }
        },
        created() {
            router.beforeEach((to, from, next) => {

                // if fast-clicking, section transition can't keep up
                // so just do a hard-refresh
                if (this.$store.state.preloadingSection) {
                    location.reload();
                }
                
                var isSection = to.meta.section ? true : false;
                var isSameSection = from.meta.parent == to.meta.parent;

                if (this.debug) {
                    console.log('isSection: ' + isSection);
                    console.log('isSameSection: ' + isSameSection);
                    console.log(from.meta.parent, to.meta.parent);
                }

                if (isSection && !isSameSection) {
                    this.changeSection(next);
                } else {
                    if (isSameSection) {
                        this.changeSubsection(next);
                    } else {
                        this.changeSection(next);
                    }
                }

            })
        },
        computed: {
           
        },
        methods: {
            changeSection(next) {
                // console.log('change section');
                this.next = next;
                this.$store.dispatch('startSectionPreload');
            },
            changeSubsection(next) {
                // console.log('change sub-section');
                next.call();
            },
            animateIn: function() {

                gsap.set(this.$refs.mask, {
                    height: 0,
                    bottom: 0,
                    top: 'auto'
                });
                gsap.set(this.$refs.centered, {
                    bottom: 0,
                    top: 'auto'
                });

                var tl = gsap.timeline({});
                tl.timeScale(0.9);
                tl.fromTo(this.$refs.black, this.wipeSpeed / 2, {
                    opacity: 0
                }, {
                    opacity: 0.2,
                    ease: Linear.easeNone
                });
                tl.fromTo(this.$refs.white, this.wipeSpeed, {
                    yPercent: 100
                }, {
                    yPercent: 0,
                    force3D: true,
                    ease: Expo.easeInOut
                }, 0);
                tl.fromTo(this.$refs.mask, this.wipeSpeed, {
                    height: '0%'
                }, {
                    height: '100%',
                    ease: Expo.easeInOut
                }, 0);
                tl.call(() => {
                    document.body.classList.add('no-scroll');
                    window.scrollTo(0, 0);
                    this.next.call();
                });

                // animate leaves
                var tl2 = gsap.timeline({
                    delay: 0.7
                });
                tl2.fromTo($('.leaf1'), 0.6, {
                    x: 40,
                    scale: 0,
                }, {
                    x: 0,
                    scale: 1,
                    ease: Power3.easeInOut
                }, '-=0.55');
                tl2.fromTo($('.leaf2'), 0.6, {
                    x: 20,
                    scale: 0,
                }, {
                    x: 0,
                    scale: 1,
                    ease: Power3.easeInOut
                }, '-=0.55');
                tl2.fromTo($('.leaf3'), 0.6, {
                    x: 0,
                    scale: 0,
                }, {
                    x: 0,
                    scale: 1,
                    ease: Power3.easeInOut
                }, '-=0.55');
                tl2.fromTo($('.leaf4'), 0.6, {
                    x: -20,
                    scale: 0,
                }, {
                    x: 0,
                    scale: 1,
                    ease: Power3.easeInOut
                }, '-=0.55');
                tl2.fromTo($('.leaf5'), 0.6, {
                    x: -40,
                    scale: 0,
                }, {
                    x: 0,
                    scale: 1,
                    ease: Power3.easeInOut
                }, '-=0.55');

                tl2.call(() => {
                    $('body').removeClass('no-scroll');
                    $('html').removeClass('no-scroll');
                    window.scrollTo(0, 0);
                    if (this.next) {
                        this.next.call();
                    }
                });

            },
            animateOut: function() {

                gsap.set(this.$refs.mask, {
                    bottom: 'auto',
                    top: 0
                });
                gsap.set(this.$refs.centered, {
                    top: 0,
                    bottom: 'auto'
                });

                var tl = gsap.timeline({})
                tl.timeScale(0.87);
                tl.fromTo(this.$refs.white, this.wipeSpeed, {
                    yPercent: 0
                }, {
                    yPercent: -100,
                    force3D: true,
                    ease: Expo.easeInOut
                });
                tl.fromTo(this.$refs.black, this.wipeSpeed * 0.9, {
                    opacity: 0.28
                }, {
                    opacity: 0,
                    ease: Linear.easeNone
                }, 0.1);
                tl.fromTo(this.$refs.mask, this.wipeSpeed, {
                    height: '100%'
                }, {
                    height: '0%',
                    ease: Expo.easeInOut
                }, 0);
                tl.call(() => {
                    this.active = false;
                });

                // animate leaves out
                if(this.firstLoad) {
                    $('.leaves').hide();
                    this.firstLoad = false;
                    
                } else {

                    var tl2 = gsap.timeline({})
                    tl2.fromTo($('.leaves'), 0.5, {
                        y: 0
                    }, {
                        y: -70,
                        opacity: 0,
                        ease: Power3.easeIn
                    }, '-0.06');
                }

            }
        },
        watch: {
            '$store.state.preloadingSection': function(val) {

                if (this.tl) {
                    this.tl.pause();
                }
                if (val) {
                    this.active = true;
                    Vue.nextTick(() => {
                        this.animateIn();
                    });
                } else {
                    this.animateOut();
                }
            }
        }
    }
</script>